import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import downloadIcon from './link-download-icon.svg'
import externalIcon from './link-external-icon.svg'

const StyledLink = styled.a`
  color: #DF0F52;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  font-weight: 600;
  text-decoration: none!important;
`

const LinkText = styled.div`
    margin-left: 16px;

    .title {
      img {
        margin-left: 8px;
      }
    }

    .subtitle {
        align-items: flex-start;
        color: gray;
        display: flex;
        font-size: 16px;
    }
`

const Link = ({ to, icon, external, subtitle, children }) => {
  const typeIcon = external ? <img src={externalIcon} alt="download" /> : <img src={downloadIcon} alt="download" />

  return (
    <StyledLink href={to} download={external ? null : to}>
      <img src={icon} alt={icon} />
      <LinkText>
        <div className="title">{children}{ typeIcon }</div>
        <div className="subtitle">{subtitle}</div>
      </LinkText>
    </StyledLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  external: PropTypes.bool
}

Link.defaultProps = {
  external: false
} 
  
export default Link
