import React from 'react'
import styled from 'styled-components'
import Link from './components/Link.js'
import logo from './jerajera-2020-logo.svg'
import sketchIcon from './sketch-file-icon.svg'
import pdfIcon from './pdf-file-icon.svg'
import swiftIcon from './swift-file-icon.svg'
import githubIcon from './github-file-icon.svg'

const StyledApp = styled.div`
  overflow-x: hidden;

  header {
    background-color: #1A191F;
    background-image: url(/header-background.svg);
    background-size: auto;
    background-position-x: -33vw;
    color: pink;
  }

  nav {
    background-color: #FBFBFF;
    overflow-y: auto;
  
    section {
      margin-bottom: 48px;
    }

    section:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 13px;
    color: #1A191F;
    letter-spacing: 0.65px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  p {
    color: #CBC9D8;
    font-size: 20px;
    line-height: 30px;
    max-width: 453px;
    margin-top: 16px;
  }

  ul {
    list-style: none;
  }

  .container {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: minmax(50vw, 1fr) minmax(50vw, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-auto-rows: 1fr;
    overflow: hidden;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: span 2;    

    flex-direction: column;
  }

  @media (max-width: 1000px) { 
    header {
      img {
        width: 33vw;
      }
    }
    .item {
      grid-column: span 2;
      overflow-y: hidden;
      padding: 48px 32px;
    }
    .container {
      grid-template-rows: 75px auto;
      height: auto;
    }
  }
`

function App() {
  return (
    <StyledApp>
      <div class="container">
        <header class="item">
            <div>
              <img src={logo} alt="logo" />
              <p>
              A design system and framework, implemented in React and Swift UI, emphasizing medium-fidelity prototyping + rapid development
              </p>
            </div>
        </header>
        <nav class="item">
          <div>
            <section>
              <h3>resources</h3>
              <ul>
                <li>
                  <Link to="#" icon={sketchIcon} subtitle="June 2020">Annotation Library</Link>
                </li>
                <li>
                  <Link to="./jera-jera-2020.pdf" icon={pdfIcon} subtitle="June 2020">Color Palette</Link>
                </li>
              </ul>
            </section>
            <section>
              <h3>bits</h3>
              <ul>
                <li>
                  <Link to="https://github.com/jimlears/jerajera-react" icon={githubIcon} external subtitle="Coming Soon">React Framework</Link>
                </li>
                <li>
                  <Link to="https://github.com/jimlears/jerajera-swiftui" icon={swiftIcon} external subtitle="Coming Soon">SwiftUI Framework</Link>
                </li>
              </ul>
            </section>
          </div>
        </nav>
      </div>
    </StyledApp>
  )
}

export default App
